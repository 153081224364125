import axios, { AxiosInstance } from 'axios';

export default class API {
    baseUrl = process.env.NEXT_PUBLIC_API_BASE_URL;
    axiosInstance: AxiosInstance;

    constructor(token: string) {
        this.axiosInstance = axios.create({
            baseURL: this.baseUrl,
            timeout: 20000,
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });

        axios.defaults.headers.post['Content-Type'] = 'application/json';
        this.axiosInstance.defaults.transformRequest = [(data) => JSON.stringify(data)];
    }

    async get(url: string, data?: any) {
        return this.axiosInstance.get(url, { data });
    }

    async post(url: string, data?: any) {
        return this.axiosInstance.post(url, data);
    }

    async put(url: string, data?: any) {
        return this.axiosInstance.put(url, data);
    }

    async patch(url: string, data?: any) {
        return this.axiosInstance.patch(url, data);
    }

    async delete(url: string) {
        return this.axiosInstance.delete(url);
    }
}

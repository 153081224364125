import { useAuth0 } from '@auth0/auth0-react';
import { Alignment, Button, Navbar, NavbarDivider, NavbarGroup } from '@blueprintjs/core';
import { useRouter } from 'next/router';
import React, { ReactNode, createContext, useEffect } from 'react';
import BreadcrumbView, { BreadcrumbAliases } from './Breadcrumb';
import { observer } from 'mobx-react-lite';
import Link from 'next/link';

type Props = {
    children?: ReactNode;
    nomenu?: boolean;
};

const breadcrumbs = new BreadcrumbAliases();
export const BreadcrumbStoreContext = createContext<BreadcrumbAliases>(breadcrumbs);

export default observer(function Layout({ children, nomenu }: Props) {
    const { logout } = useAuth0();
    const router = useRouter();
    const isProduction = process.env.NEXT_PUBLIC_ENV_TYPE == 'PRODUCTION';

    useEffect(() => {
        breadcrumbs.clearAliases(router.asPath);
    }, [router]);

    return (
        <>
            <div>
                <Navbar className={'navbar'}>
                    <NavbarGroup align={Alignment.LEFT}>
                        {isProduction ? (
                            <Link href="/">
                                <img
                                    src="/logo.svg"
                                    style={{
                                        height: 20,
                                        marginRight: 15,
                                        width: 144.22,
                                        cursor: 'pointer',
                                    }}
                                />
                            </Link>
                        ) : (
                            <Link href="/">
                                <label
                                    style={{
                                        color: '#FF7D40',
                                        fontSize: '2rem',
                                        cursor: 'pointer',
                                    }}>
                                    STAGING
                                </label>
                            </Link>
                        )}

                        {nomenu ? null : (
                            <>
                                <NavbarDivider />
                                <Link href="/categories">
                                    <Button
                                        minimal
                                        icon="th"
                                        text="Categories"
                                        style={{ color: '#f4f4f4' }}
                                    />
                                </Link>
                                <Link href="/coaches">
                                    <Button
                                        minimal
                                        icon="person"
                                        text="Coaches"
                                        style={{ color: '#f4f4f4' }}
                                    />
                                </Link>
                                <Link href="/programs">
                                    <Button
                                        minimal
                                        icon="calendar"
                                        text="Programs"
                                        style={{ color: '#f4f4f4' }}
                                    />
                                </Link>
                                <Link href="/routines">
                                    <Button
                                        minimal
                                        icon="film"
                                        text="Routines"
                                        style={{ color: '#f4f4f4' }}
                                    />
                                </Link>
                                <Link href="/exercises">
                                    <Button
                                        minimal
                                        icon="learning"
                                        text="Exercises"
                                        style={{ color: '#f4f4f4' }}
                                    />
                                </Link>
                                <Link href="/users">
                                    <Button
                                        minimal
                                        icon="user"
                                        text="Users"
                                        style={{ color: '#f4f4f4' }}
                                    />
                                </Link>
                                <Link href="/firmware">
                                    <Button
                                        minimal
                                        icon="build"
                                        text="Firmware"
                                        style={{ color: '#f4f4f4' }}
                                    />
                                </Link>
                                <Link href="/bypasses">
                                    <Button
                                        minimal
                                        icon="unlock"
                                        text="Bypasses"
                                        style={{ color: '#f4f4f4' }}
                                    />
                                </Link>
                                <Link href="/featured">
                                    <Button
                                        minimal
                                        icon="clean"
                                        text="Featured"
                                        style={{ color: '#f4f4f4' }}
                                    />
                                </Link>
                            </>
                        )}
                    </NavbarGroup>

                    {nomenu ? null : (
                        <NavbarGroup align={Alignment.RIGHT}>
                            <Button
                                onClick={() =>
                                    logout({
                                        returnTo:
                                            typeof window !== 'undefined'
                                                ? window.location.origin
                                                : undefined,
                                    })
                                }
                                text="Logout"
                                intent="primary"
                            />
                        </NavbarGroup>
                    )}
                    {!isProduction && <NavbarGroup align={Alignment.RIGHT}></NavbarGroup>}
                </Navbar>

                <main>
                    <div style={{ maxWidth: 920, margin: '0 auto 50px' }}>
                        <BreadcrumbStoreContext.Provider value={breadcrumbs}>
                            <BreadcrumbView />
                            {children}
                        </BreadcrumbStoreContext.Provider>
                    </div>
                </main>

                <footer></footer>

                <style jsx global>{`
                    .navbar {
                        boxshadow: 'none';
                        background-color: #434043;
                    }
                    .navbar .bp4-navbar-divider {
                        border-left-color: rgba(255, 255, 255, 0.5) !important;
                    }

                    .navbar .bp4-icon {
                        color: #e2e2e2 !important;
                    }
                `}</style>
            </div>
        </>
    );
});

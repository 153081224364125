import { Spinner } from '@blueprintjs/core';
import React from 'react';
import Layout from './Layout';

export default function Loading() {
    return (
        <Layout nomenu>
            <div style={{ margin: 20 }}>
                <Spinner size={100} />
            </div>
        </Layout>
    );
}

import { useAuth0 } from "@auth0/auth0-react";
import { action, autorun, runInAction } from "mobx";
import { observer } from "mobx-react-lite";
import { ReactElement, useEffect } from "react";
import { useAuthStore } from "./AuthStoreProvider";

type StoreDataProps = {
  children: ReactElement<any, any>;
};

const StoreData = observer(function StoreData({ children }: StoreDataProps) {
  const {
    user,
    isAuthenticated,
    getAccessTokenSilently,
    isLoading: auth0Loading,
  } = useAuth0();

  const authStore = useAuthStore()

  useEffect(() => {
    return autorun(() => {
      runInAction(() => {
        if (auth0Loading === false && !isAuthenticated) {
          authStore.loaded = true;
        } else if (isAuthenticated && auth0Loading === false) {
          getAccessTokenSilently()
            .then(
              action((res) => {
                authStore.hydrate({accessToken: res});
              })
            )
        }
      });
    });
  }, [isAuthenticated, auth0Loading, getAccessTokenSilently]);

  return children;
});

export default StoreData;



import { action, computed, makeObservable, observable } from "mobx";
import API from "./API";


export type AuthHydration = {
  accessToken: string | null;
};

export class AuthStore {
  loaded: boolean = false;
  accessToken: string | null = null;

  constructor() {
    makeObservable(this, {
      accessToken: observable,
      loaded: observable,
      isAuthenticated: computed,
      api: computed,
      hydrate: action,
    });
  }
  get isAuthenticated() {
    return !(this.accessToken == null);
  }

  get api() {
    return new API(this.accessToken ?? "");
  }

  hydrate(data?: AuthHydration) {
    if (data) this.accessToken = data.accessToken;
  }
}
import React, { createContext, ReactNode, useContext } from "react";
import { AuthStore } from "./AuthStore";


let store: AuthStore;
const StoreContext = createContext<AuthStore | undefined>(undefined);

export function useAuthStore() {
  const context = useContext(StoreContext);

  if (context === undefined) {
    throw new Error("useAuthStore must be used within RootStoreProvider");
  }

  return context;
}

function initializeStore(): AuthStore {
  const isServer = typeof window === "undefined";
  const _store = store ?? new AuthStore();

  if (isServer) return _store;

  if (!store) store = _store;

  return _store;
}

export function AuthStoreProvider({
  children,
}: {
  children: ReactNode;
}) {
  const store = initializeStore();

  return (
    <StoreContext.Provider value={store}>{children}</StoreContext.Provider>
  );
}